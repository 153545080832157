import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Typography } from '@material-ui/core';
import { Chat } from '@material-ui/icons';
import { useHistory } from 'react-router';
import clsx from 'clsx';

import { PricingCard, FeatureRow, FeaturesHeader, CustomPricingCard } from 'src/scenes/Home/scenes/Pricing/components';
import { pricing, featuresByPlan } from 'src/scenes/Home/scenes/Pricing/pricingData';
import { NviroButton, SwitchIOS } from 'src/components';
import { payApi } from 'src/services';


const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(7),
  },
  title: {
    fontSize: '4.375rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '3rem',
      lineHeight: '58px',
      textAlign: 'center',
    },
  },
  subTitle: {
    fontSize: '1.25rem',
    color: theme.palette.common.black,
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
    },
  },
  yearlyPeriodicity: {
    color: theme.palette.primary.main,
  },
  notSelectedPeriod: {
    color: theme.palette.common.gray,
  },
  monthlyPeriodicity: {
    color: theme.palette.common.black,
  },
  pricingSection: {
    paddingLeft: theme.spacing(15),
    paddingRight: theme.spacing(15),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  pricingCards: {
    paddingTop: theme.spacing(5),
  },
  periodicityText: {
    fontSize: '1.5rem',
  },
  comparisonSection: {
    paddingTop: theme.spacing(16),
    paddingBottom: theme.spacing(14),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  comparisonTitle: {
    fontSize: '3.75rem',
    color: theme.palette.common.gray600,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '3.125rem',
      lineHeight: '58px',
      textAlign: 'center',
    },
  },
  comparisonSubtitleContainer: {
    paddingTop: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  comparisonSubtitle: {
    fontSize: '3rem',
    color: theme.palette.common.gray600,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.875rem',
      lineHeight: '34px',
      fontWeight: 500,
    },
  },
  featuresComparison: {
    minWidth: '1000px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      minWidth: 'unset',
    },
  },
  faqSection: {
    paddingTop: theme.spacing(10),
    backgroundColor: theme.palette.common.lightGray,
  },
  faqTitle: {
    fontSize: '3.75rem',
    color: theme.palette.common.gray600,
    fontWeight: 600,
  },
  contactSection: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(10),
    backgroundColor: theme.palette.primary.main,
  },
  contactText: {
    color: theme.palette.common.white,
    fontWeight: 600,
    fontSize: '4.375rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '3.125rem',
      lineHeight: '58px',
      textAlign: 'center',
    },
  },
  contactIcon: {
    color: theme.palette.common.white,
    fontSize: '5.5rem',
  },
  contactButton: {
    width: theme.spacing(36),
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(25),
      marginTop: theme.spacing(3),
    },
  },
  badgeMonth: {
    position: 'absolute',
    height: '50px',
    background: '#FFA700',
    width: '197px',
    textAlign: 'center',
    fontSize: '20px',
    lineHeight: '50px',
    fontFamily: 'sans-serif',
    color: '#FFF',
    transform: 'rotate(315deg)',
    top: '36px',
    left: '-38px',
    boxShadow: 'inset 0px 0px 0px 4px rgba(255, 255, 255, 0.34)',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      top: '12px',
      left: '-5px',
      height: '35px',
      transform: 'rotate(339deg)',
      lineHeight: '39px',
    },
  },
  badgeMonthLeft: {
    position: 'absolute',
    content: '""',
    display: 'block',
    top: '50px',
    left: '0px',
    height: '9px',
    width: '9px',
    background: 'linear-gradient(135deg, #e49400 50%, rgba(90, 146, 106, 0) 50.1%)',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  badgeMonthRight: {
    position: 'absolute',
    content: '""',
    display: 'block',
    top: '50px',
    left: '188px',
    height: '9px',
    width: '9px',
    background: 'linear-gradient(135deg, #e49400 50%, rgba(90, 146, 106, 0) 50.1%)',
    transform: 'rotate(90deg)',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const PricingContainer = () => {
  const [ isYearly, setIsYearly ] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const [ plans, setPlans ] = useState([]);

  useEffect(() => {
    const fetchPricing = async () => {
      const plans = await payApi.getCompanyPlans();
      const plansWithExtras = plans.map(plan => ({ ...plan, ...pricing[plan.id] }));
      setPlans(plansWithExtras);
    };

    fetchPricing();
    document.title = 'Planes - Nviro Capture';
  }, []);

  return (
    <Box className={classes.container}>
      <Grid className={classes.pricingSection} container alignItems="center" direction="column">
        <Grid item>
          <Typography className={classes.title} color="primary">Elige el mejor plan para tu negocio</Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.subTitle}>
            Múltiples planes adaptados a tus necesidades ¿Cuál es tu plan ideal?
          </Typography>
        </Grid>
        <Grid item>
          <Box pt={10} display="flex" alignItems="center">
            <Typography className={clsx(classes.periodicityText, isYearly ? classes.notSelectedPeriod : classes.monthlyPeriodicity)}>
              MENSUAL
            </Typography>
            <SwitchIOS checked={isYearly} onChange={() => setIsYearly(ps => !ps)} />
            <Typography className={clsx(classes.periodicityText, isYearly ? classes.yearlyPeriodicity : classes.notSelectedPeriod)}>
              ANUAL (15% DE DESCUENTO)
            </Typography>
          </Box>
        </Grid>
        <Grid item className={classes.pricingCards} container justifyContent="center">
          {plans.map((plan, index) =>
            <Grid item key={index}>
              <PricingCard {...plan} isYearly={isYearly} />
            </Grid>,
          )}
          <Grid item>
            <CustomPricingCard />
          </Grid>
        </Grid>
      </Grid>

      <Grid className={classes.comparisonSection} container alignItems="center" justifyContent="center" direction="column">
        <Grid item>
          <Typography className={classes.comparisonTitle}>Comparación de planes</Typography>
        </Grid>
        <Box className={classes.featuresComparison}>
          <FeaturesHeader plans={[ ...plans, { name: 'Personalizado' } ].map(plan => plan.name)} />
          {featuresByPlan['Características'].map(({ name, plan }, idx) =>
            <FeatureRow key={idx} name={name} plan={plan} />,
          )}
        </Box>
      </Grid>
      {/* // comentado temporalmente hasta que existan preguntas de los planes
      <Grid className={classes.faqSection} container alignItems="center" direction="column">
        <Grid item>
          <Typography className={classes.faqTitle} color="primary">Preguntas frecuentes</Typography>
        </Grid>
        <Grid item>
          <Faq faq={faq} />
        </Grid>
      </Grid>
      */}
      <Grid className={classes.contactSection} container alignItems="center" direction="column">
        <Grid item>
          <Chat className={classes.contactIcon} />
        </Grid>
        <Grid item>
          <Typography className={classes.contactText} color="primary">Si aún tienes dudas</Typography>
        </Grid>
        <Grid item>
          <NviroButton className={classes.contactButton} onClick={() => history.push('/contact')}>Contáctanos</NviroButton>
        </Grid>
      </Grid>
    </Box>
  );
};


export { PricingContainer };