import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, InputLabel, TextField, FormHelperText } from '@material-ui/core';

import { AlertWrapper, AutocompleteWrapper, DialogButton } from 'src/components';
import { useFetchStatus } from 'src/hooks';


const formatRut = rut => {
  const rutClean = rut.replace(/^0+|[^0-9kK]+/g, '').toUpperCase();
  if (rutClean.length <= 1) {
    return rutClean;
  }
  const dv = rutClean.charAt(rutClean.length - 1);
  const rutWithoutDv = rutClean.slice(0, -1);
  const rutFormatted = rutWithoutDv.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return `${rutFormatted}-${dv}`;
};

const checkValidRut = rut => {
  if (!/^0*(\d{1,3}(\.?\d{3})*)-?([\dkK])$/.test(rut)) {
    return false;
  }

  const rutClean = rut.replace(/^0+|[^0-9kK]+/g, '').toUpperCase();
  const dv = rutClean.charAt(rutClean.length - 1);
  const rutWithoutDv = rutClean.slice(0, -1);
  let sum = 0;
  let multiplier = 2;

  for (let i = rutWithoutDv.length - 1; i >= 0; i--) {
    sum += parseInt(rutWithoutDv.charAt(i)) * multiplier;
    multiplier = multiplier === 7 ? 2 : multiplier + 1;
  }

  const expectedDv = 11 - (sum % 11);
  const calculatedDv = expectedDv === 11 ? '0' : expectedDv === 10 ? 'K' : expectedDv.toString();

  return dv === calculatedDv;
};

const emptyOption = { label: '', value: '' };

const BillingDataAddDialog = ({ actions, options, prevBillingData = {} }) => {

  const [ billingData, setBillingData ] = useState({
    rut: '',
    legalName: '',
    address: '',
    commune: '',
    contact: '',
    economicActivityOption: prevBillingData.economicActivityId ?
      { label: prevBillingData.economicActivityName, value: prevBillingData.economicActivityId }
      : emptyOption,
    ...prevBillingData,
  });

  const [ isValidRut, setIsValidRut ] = useState(true);

  const { handleConfirm, fetchStatus, errorMessage, hasError, hasBadData } = useFetchStatus({
    confirmFn: () => actions.addBillingData({
      rut,
      legalName,
      address,
      commune,
      contact,
      economicActivityId: economicActivityOption.value,
    }),
    closeFn: actions.closeDialog,
    validationFn: () => ({ isValid: hasRut && hasLegalName && hasAddress && hasEconomicActivitySelected && hasCommune && hasContact }),
  });

  const handleChange = e => setBillingData({ ...billingData, [e.target.name]: e.target.value });
  const handleSelect = obj => setBillingData(prevBillingData => ({ ...prevBillingData, ...obj }));

  const handleRutChange = e => {
    const rut = formatRut(e.target.value);
    setBillingData(prevBillingData => ({ ...prevBillingData, rut }));
    setIsValidRut(checkValidRut(rut) && rut !== '');
  };

  const { rut, legalName, address, commune, contact, economicActivityOption } = billingData;

  const hasRut = Boolean(rut);
  const hasLegalName = Boolean(legalName);
  const hasEconomicActivitySelected = Boolean(economicActivityOption.value);
  const hasAddress = Boolean(address);
  const hasCommune = Boolean(commune);
  const hasContact = Boolean(contact);

  const commonProps = {
    onChange: handleChange,
    variant: 'outlined',
    size: 'small',
    fullWidth: true,
  };

  return (
    <>
      <DialogTitle id="form-dialog-title">Agregar datos de facturación</DialogTitle>
      <DialogContent>
        <Box p={1}>
          <Box px={7}>
            <Box my={2}>
              <InputLabel shrink required>Rut empresa</InputLabel>
              <TextField
                name="rut"
                value={rut}
                error={hasBadData && !hasRut}
                placeholder="Ej: 12.345.678-9"
                {...commonProps}
                onChange={handleRutChange}
              />
              {hasBadData && !hasRut && <FormHelperText error>Debes ingresar un rut</FormHelperText>}
              {hasRut && !isValidRut && <FormHelperText error>El rut ingresado no es válido</FormHelperText>}
            </Box>
            <Box my={2}>
              <InputLabel shrink required>Razón social</InputLabel>
              <TextField
                name="legalName"
                value={legalName}
                error={hasBadData && !hasLegalName}
                placeholder="Ej: Empresa Ltda"
                {...commonProps}
                onChange={handleChange}
              />
              {hasBadData && !hasLegalName && <FormHelperText error>Debes ingresar una razón social</FormHelperText>}
            </Box>
            <Box my={2}>
              <InputLabel shrink required>Giro</InputLabel>
              <AutocompleteWrapper
                value={billingData.economicActivityOption}
                options={options.economicActivities}
                onChange={(event, selected) => handleSelect({ economicActivityOption: selected })}
                getOptionSelected={(option, value) => option.value === value.value}
                error={hasBadData && !hasEconomicActivitySelected}
              />
              {hasBadData && !hasEconomicActivitySelected && <FormHelperText error>Debes seleccionar un giro</FormHelperText>}
            </Box>
            <Box my={2}>
              <InputLabel shrink required>Dirección</InputLabel>
              <TextField
                name="address"
                value={address}
                error={hasBadData && !hasAddress}
                placeholder="Ej: Nueva de Lyon 96"
                {...commonProps}
                onChange={handleChange}
              />
              {hasBadData && !hasAddress && <FormHelperText error>Debes ingresar una dirección</FormHelperText>}
            </Box>
            <Box my={2}>
              <InputLabel shrink required>Comuna</InputLabel>
              <TextField
                name="commune"
                value={commune}
                error={hasBadData && !hasCommune}
                placeholder="Ej: Providencia"
                {...commonProps}
                onChange={handleChange}
              />
              {hasBadData && !hasCommune && <FormHelperText error>Debes ingresar una comuna</FormHelperText>}
            </Box>
            <Box my={2}>
              <InputLabel shrink required>Contacto</InputLabel>
              <TextField
                name="contact"
                value={contact}
                error={hasBadData && !hasContact}
                placeholder="Ej: +56 9 4246 8029 o contacto@nviro.cl"
                {...commonProps}
                onChange={handleChange}
              />
              {hasBadData && !hasContact && <FormHelperText error>Debes ingresar un contacto</FormHelperText>}
            </Box>
          </Box>
        </Box>
        {hasError && <AlertWrapper severity="error">{errorMessage}</AlertWrapper>}
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        <DialogButton onClick={handleConfirm} fetchStatus={fetchStatus} color="primary">Confirmar</DialogButton>
      </DialogActions>
    </>
  );
};

BillingDataAddDialog.propTypes = {
  actions: PropTypes.object,
  options: PropTypes.object,
  prevBillingData: PropTypes.object,
};


export {
  BillingDataAddDialog,
};
