import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, Typography, Divider, Link, FormHelperText, Tooltip }
  from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { AlertWrapper, DialogButton, SelectChip } from 'src/components';
import { useFetchStatus } from 'src/hooks';
import { CheckIcon } from 'src/utils/mdIcons';
import { Payment } from '@material-ui/icons';
import { pricing } from 'src/scenes/Home/scenes/Pricing/pricingData';
import { thousandsDotSeparator as tds } from 'src/utils/util';
import { upperCaseOnlyFirstLetter as ucofl } from 'src/utils/formatters';
import { truncateWithEllipsis as twe } from 'src/utils/util';


const useStyles = makeStyles(theme => ({
  group: {
    border: `1px solid ${theme.palette.common.gray400}`,
    borderRadius: '6px',
  },
  title: {
    fontWeight: '600',
    textTransform: 'uppercase',
  },
  description: {
    color: theme.palette.common.gray600,
  },
  price: {
    fontWeight: 500,
    fontSize: '1.75rem',
  },
  link: {
    cursor: 'pointer',
  },
  fontWeight500: {
    fontWeight: 500,
  },
}));

const billingFrequencyMapper = { 0: 'monthly', 1: 'annual' };

const SubscriptionCreateDialog = ({ selectedPlan, creditCard, billingData, actions }) => {

  const classes = useStyles();
  const [ selectedFrequency, setSelectedFrequency ] = useState(0);

  const { fetchStatus, errorMessage, handleConfirm, hasError, hasBadData } = useFetchStatus({
    confirmFn: () => actions.subscribePlan(billingFrequencyMapper[selectedFrequency]),
    validationFn: () => ({ isValid: hasBillingData && hasCreditCard }),
  });

  const hasCreditCard = Boolean(creditCard?.lastDigits);
  const hasBillingData = Boolean(billingData?.id);

  const currentPricing = pricing[selectedPlan.id];
  const isYearly = selectedFrequency === 1;

  const economicActivityLabel = hasBillingData ?
    [ billingData.economicActivityCode, ucofl(billingData.economicActivityName) ].join(' - ') : '';
  return (
    <>
      <DialogTitle id="form-dialog-title">
        Confirmación de suscripción
      </DialogTitle>
      <DialogContent>
        <Box py={2} px={6}>
          <Box className={classes.group} p={3}>
            <Box display="flex">
              <Box>
                <Typography color="textPrimary" variant="h6" className={classes.title}>Plan {selectedPlan.name}</Typography>
                <Box>
                  <Typography color="textSecondary" variant="body2" className={classes.description}>
                    {selectedPlan.description}
                  </Typography>
                </Box>
              </Box>
              <Box flexGrow={1} />
              <Box textAlign="end">
                <Typography className={classes.price} variant="h4">
                  {tds(Math.floor(isYearly ? (selectedPlan.amount * 0.85 * 12) : selectedPlan.amount))} {selectedPlan.unitValueId}
                  <Typography component="span" variant="body2">/ {isYearly ? 'año' : 'mes'}</Typography>
                </Typography>
                <Typography className={classes.description} variant="body2">
                  + {tds(Math.floor(selectedPlan.extraUserAmount))} {selectedPlan.unitValueId} por usuario extra al mes |
                  + {tds(Math.floor(selectedPlan.extraGbStorageAmount))} {selectedPlan.unitValueId} por GB extra al mes
                </Typography>
              </Box>
            </Box>
            <Box py={2}>
              <Divider />
            </Box>
            {currentPricing.features.data.map((feat, index) =>
              <Box key={index} display="flex" py={1} width="100%" alignItems="center">
                <Box pr={2} display="flex" alignItems="center">
                  <CheckIcon fontSize="small" color="primary" />
                </Box>
                <Box flexGrow={1}>
                  <Typography color="textPrimary" variant="body2">{feat}</Typography>
                </Box>
              </Box>,
            )}
          </Box>
          <Box mt={2}>
            <Typography variant="h6">Frecuencia de pago</Typography>
          </Box>
          <Box className={classes.group} p={3}>
            <SelectChip
              value={selectedFrequency}
              options={[ { label: 'Mensual', value: 0 }, { label: 'Anual', value: 1 } ]}
              onChange={option => setSelectedFrequency(option.value)}
            />
          </Box>
          <Box mt={2}>
            <Typography variant="h6">Información de facturación</Typography>
          </Box>
          <Box className={classes.group} p={3}>
            {hasBillingData ?
              <Box>
                <Typography color="textPrimary" variant="body1">
                  <strong>{billingData.legalName}</strong>
                </Typography>
                <Typography className={classes.fontWeight500} color="textPrimary" variant="body1">
                  {billingData.rut}
                </Typography>
                <Typography className={classes.fontWeight500} color="textPrimary" variant="body2">
                  <Tooltip title={economicActivityLabel}>
                    <span>
                      {twe(economicActivityLabel, 64)}
                    </span>
                  </Tooltip>
                </Typography>
                <br />
                <Typography color="textPrimary" variant="body2">
                  {billingData.commune}
                </Typography>
                <Typography color="textPrimary" variant="body2">
                  {billingData.address}
                </Typography>
                <br />
                <Typography color="textPrimary" variant="body2">
                  {billingData.contact}
                </Typography>
              </Box>
              : <Box display="flex" alignItems="center">
                <Typography variant="body2">No se han agregado datos de facturación</Typography>
                <Box flexGrow={1} />
                <Link className={classes.link} variant="body2" onClick={actions.goToSubscriptionUrl}>
                  Ir a agregar datos de facturación
                </Link>
              </Box>
            }
          </Box>
          {hasBadData && !hasBillingData && <FormHelperText error>Debes agregar la información de facturación</FormHelperText>}
          <Box mt={2}>
            <Typography variant="h6">Medio de pago</Typography>
          </Box>
          <Box className={classes.group} p={3}>
            {hasCreditCard ?
              <Box display="flex" alignItems="center">
                <Payment />
                <Box ml={1}>
                  <Typography variant="body2">
                    {creditCard.type} terminada en {creditCard.lastDigits}
                  </Typography>
                </Box>
              </Box>
              : <Box display="flex" alignItems="center">
                <Typography variant="body2">No se ha asociado un medio de pago</Typography>
                <Box flexGrow={1} />
                <Link className={classes.link} variant="body2" onClick={actions.goToSubscriptionUrl}>
                  Ir a agregar medio de pago
                </Link>
              </Box>
            }
          </Box>
          {hasBadData && !hasCreditCard && <FormHelperText error>Debes agregar un medio de pago</FormHelperText>}
          <br />
          {hasError && <AlertWrapper severity="error">{errorMessage}</AlertWrapper>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        <DialogButton fetchStatus={fetchStatus} onClick={handleConfirm} color='primary'>
          ¡Sí, suscribir!
        </DialogButton>
      </DialogActions>
    </>
  );
};

SubscriptionCreateDialog.propTypes = {
  actions: PropTypes.object,
  selectedPlan: PropTypes.object,
  creditCard: PropTypes.object,
  billingData: PropTypes.object,
};


export {
  SubscriptionCreateDialog,
};
