import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Box, Divider, Typography, Avatar, Chip, List, ListSubheader, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDropDown, CreditCard, /*DataUsage, */ Link, Person } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router';
import urlJoin from 'url-join';
import clsx from 'clsx';

import { WebContext } from 'src/scenes/web-context';
import config from 'src/config/local';
import { DrawerWrapper } from 'src/components';
// import { CashMultiple } from 'src/utils/mdIcons';
import { usersApi, companiesApi } from 'src/services';
import { CompanySelectDialog } from 'src/components/header/CompanySelectDialog';
import checkers from 'src/utils/checkers';
import { getIsNviroOrCswEmployee } from 'src/utils/util';


const { serverUrl } = config;

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: 'transparent !important',
    border: `1px solid ${theme.palette.common.gray}`,
    height: '32px',
    width: '32px',
    marginRight: theme.spacing(1),
  },
  listItemSelected: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    color: theme.palette.common.white,
  },
  listItem: {
    borderRadius: '6px',
  },
  list: {
    paddingTop: theme.spacing(0),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  listItemIcon: {
    minWidth: '36px',
  },
  sectionHeader: {
    lineHeight: 0,
  },
  chip: {
    fontWeight: 'bold',
    width: '100%',
  },
  chipLabel: {
    flexGrow: 1,
  },
  workIn: {
    lineHeight: '1.2',
  },
}));

const itemList = [
  // {
  //   Icon: MonitorDashboard,
  //   label: 'Perfil de empresa',
  //   endPath: 'profile',
  //   isSubscriptionRequired: true,
  //   isRequiredToBeAnEmployeeOfCswOrNviro: true,
  // },
  {
    Icon: CreditCard,
    label: 'Suscripción',
    endPath: 'subscription',
    isSubscriptionRequired: false,
    isRequiredToBeAnEmployeeOfCswOrNviro: false,
    subPaths: [ 'plans' ],
    sectionName: 'Plan y facturación',
  },
  // Comentados de momento ya que aun no se tiene la info para mostrarlos.... Pero ya se viene!!!
  // {
  //   Icon: DataUsage,
  //   label: 'Uso de la plataforma',
  //   endPath: 'usage',
  //   isSubscriptionRequired: false,
  //   isRequiredToBeAnEmployeeOfCswOrNviro: false,
  // },
  // {
  //   Icon: CashMultiple,
  //   label: 'Historial de pagos',
  //   endPath: 'payment-history',
  //   isSubscriptionRequired: false,
  //   isRequiredToBeAnEmployeeOfCswOrNviro: false,
  // },
  {
    Icon: Link,
    label: 'Vínculos con Monitor',
    endPath: 'linked-apps',
    subPaths: [ 'allowed-campaigns' ],
    isSubscriptionRequired: true,
    isRequiredToBeAnEmployeeOfCswOrNviro: true,
    sectionName: 'Vínculos con otras apps',
  },
];

const getIndex = (currentPathname, filteredItemList) => filteredItemList.findIndex(item => {
  const lastString = currentPathname.split('/').pop();
  return item.endPath === lastString || item.subPaths?.includes(lastString);
});

const CompanySettingsDrawer = ({ company, changeCompany }) => {
  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();
  const [ companies, setCompanies ] = useState({ companies: [] });

  const context = useContext(WebContext);
  const { currentUser: { email } } = context;

  const isNviroOrCswEmployee = getIsNviroOrCswEmployee(email);
  const filteredItemList = itemList.filter(item => !item.isRequiredToBeAnEmployeeOfCswOrNviro || isNviroOrCswEmployee);

  const [ selectedIndex, setSelectedIndex ] = useState(getIndex(pathname, filteredItemList));

  const [ openCompaniesDialog, setOpenDialog ] = useState(false);

  const baseUrl = `/web/company`;

  const handleSelectedIndex = index => () => {
    history.push(urlJoin(baseUrl, itemList[index].endPath));
    setSelectedIndex(index);
  };

  useEffect(() => {
    setSelectedIndex(getIndex(pathname, filteredItemList));
  }, [ pathname, filteredItemList ]);

  useEffect(() => {
    const fetchData = async () => {
      const companies = await companiesApi.getSelectableCompanies();
      setCompanies(companies);
    };
    fetchData();
  }, []);

  const commonListItemProps = { button: true, component: 'a', classes: { root: classes.listItem, selected: classes.listItemSelected } };

  const getCompanyLetters = companyName => {
    if (companyName) {
      const [ firstLetter, secondLetter = ' ' ] = companyName.split(' ');
      return `${firstLetter[0].toUpperCase()}${secondLetter[0].toUpperCase().trim()}`;
    }
    return false;
  };

  const handleCloseCompaniesDialog = async selectedCompany => {
    setOpenDialog(false);
    if (selectedCompany.id !== company.id) {
      const role = await companiesApi.changeCompany(selectedCompany.id);
      changeCompany({ ...selectedCompany, ...role });
      const rolePermissions = await usersApi.getPermissionsByRole();
      context.changeRolePermissions(rolePermissions);
      history.push(`/web`);
    }
  };

  const isActiveSub = checkers.isActiveSub(company.subscription?.statusId);
  const isTrialPeriod = checkers.isTrialPeriod(company.subscription);

  return (
    <DrawerWrapper>
      <Box mt={12}>
        <Box px={4} py={3} display="flex" flexDirection="column" alignItems="flexStart">
          <Typography variant="caption" className={classes.workIn}>Trabajar en:</Typography>
          <Box display="flex" width="100%">
            <Chip variant="outlined" classes={{ label: classes.chipLabel }} color='primary' className={classes.chip}
              avatar={
                <Avatar className={clsx({ [classes.avatar]: Boolean(company.image) })}
                  {...(company.image ? { src: urlJoin(serverUrl, company.image) } : {})}
                >
                  {company.private ? <Person /> : getCompanyLetters(company.name)}
                </Avatar>
              }
              deleteIcon={<ArrowDropDown />} onDelete={() => setOpenDialog(true)}
              label={company ? company.name : ''} onClick={() => setOpenDialog(true)} />
          </Box>
        </Box>
        <List className={classes.list}>
          {filteredItemList.map(({ Icon, label, isSubscriptionRequired, sectionName }, itemIndex) => (
            (isActiveSub || isTrialPeriod || !isSubscriptionRequired) &&
            <Fragment key={itemIndex}>
              {sectionName &&
                <>
                  <Box {...(itemIndex === 0 ? { pb: 2 } : { py: 2 })} mx={-1}>
                    <Divider />
                  </Box>
                  <Box py={2}>
                    <ListSubheader className={classes.sectionHeader} color="primary">
                      {sectionName}
                    </ListSubheader>
                  </Box>
                </>
              }
              <ListItem
                selected={selectedIndex === itemIndex}
                onClick={handleSelectedIndex(itemIndex)} {...commonListItemProps}
              >
                <ListItemIcon classes={{ root: classes.listItemIcon }}>
                  <Icon />
                </ListItemIcon>
                <ListItemText primary={label} />
              </ListItem>
            </Fragment>
          ))}
        </List>
      </Box>
      {companies.length > 1 &&
        <CompanySelectDialog
          companies={companies}
          selectedCompany={company}
          actions={{ handleCloseCompaniesDialog, getCompanyLetters }}
          open={openCompaniesDialog}
        />
      }
    </DrawerWrapper>
  );
};

CompanySettingsDrawer.propTypes = {
  company: PropTypes.object,
  changeCompany: PropTypes.func,
};


export { CompanySettingsDrawer };